.svc-box  {
    background: #fff;
    border: 1px solid #CCD6E5;
    width: auto;
    padding: 7px 10px;
    border-radius: 4px;
    .title-price {
      color: #FF7528;
    }
    .item-icon {
      padding: 0 7px;
      img{
        width: 18px;
        height: 18px;
        vertical-align: middle;
      }
    }
    .item-text {
      color: #333;
    }
  }

  .svc-detail-box {
    background: #fff;
    //padding: 15px 0px 0 15px;
    //width: 440px;
    //height: 380px;
    .detail-title {
      padding-bottom: 20px;
      font-size: 14px;
      color: #FF7528;
      text-align: center;
    }
    .detail-contend {
      //height: 300px;
      overflow-y: auto;
      .detail-item {
        //padding: 0 0 18px 30px;
        position: relative;
      }
      .detail-item-icon {
        position: absolute;
        top: 0;
        left: 0;
        img{
          width: 18px;
          height: 18px;
        }
      }
      .detail-item-text {
        padding-bottom: 8px;
        font-size: 14px;
        color: #333;

      }
      .item-desc-title {
          text-align: center;
      }
      .detail-item-desc {
        color: #999;
        font-size: 12px;
      }
      .detail-coupon-item {
        //padding-bottom: 10px;
        .detail-item-desc {
          padding-bottom: 5px;
        }
      }
      .detail-center-line {
        background: #EEF1F6;
        border-top: 1px solid #EEF1F6;
        width: 36px;
        display: inline-block;
        vertical-align: middle;
      }
      &::-webkit-scrollbar{ height: 4px; width:4px;}
      &::-webkit-scrollbar-thumb{ background-color: #b4b4b4; border-radius: 4px; }
      &::-webkit-scrollbar-button{ display: none; }
      &::-webkit-scrollbar-track{ background-color: #f4f4f4; }
    }
  }
  .overseas-stu-tag{
    border:1px solid #007FE9;
    color:#007FE9;
    padding: 1px;
    font-size:12px;
    vertical-align: middle;
    cursor: help;
    em{
        background: rgba(57,142,254,.2);
        font-style: normal;
        padding-left:1px;
        margin-left: 1px;
    }
}
