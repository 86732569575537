@import "./list.scss";

#__next {
  .header-wrapper.header-wrapper-v2 {
    padding-top: 0;
  }

  // 新版头尾
  .page-header-footer-v2 {
    .header-wrapper-v2 .page-list-search-form {
      margin-top: 0;
    }
  }

  // 国际
  .filter-v2 .result-wrapper .flight-list {

    .flight-item {

      .taxation-tip-msg {
        right: -115px;
      }

      .flight-seats {
        .seat-row {
          padding-top: 14px;
        }

        .seat-row-v3 .seat-operate {
          .price.price-b {
            font-size: 22px;
            line-height: 18px;
          }

          .seat-action {
            width: 60px;

            .book-tooltip {
              position: absolute;
              right: 19px;
              top: -17px;
              bottom: auto;
            }

            button {
              min-width: 40px;
            }
          }
        }
      }
    }
  }

  // 搜索框
  // 覆盖新头尾
  .date-components input {
    font-family: Helvetica Neue, Tahoma, Arial, PingFangSC-Regular, Hiragino Sans GB, Microsoft Yahei, sans-serif;
  }
}

