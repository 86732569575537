@import './variable.scss';

.filter-v2 {
  .result-wrapper .flight-list.root-flights .flight-item .flight-box .flight-airline .tongcheng-logo {
    width: 18px;
    height: 18px;
    margin: auto;
    transform: translateY(-50%)
  }
  .body-wrapper {
    margin-top: 3px;
  }

  .flt-selected {
    margin-top: 36px;
  }

  .result-wrapper {
    width: 1180px;
    margin-top: 2px;

    .btn.btn-book {
      background-image: linear-gradient(to right, #ffa50a, #ff7700);
      color: #fff;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffa50a', endColorstr='#ff7700', GradientType=1);

      &.selected-flight {
        background-image: linear-gradient(170deg, #26c28d 0%, #00a876 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#26C28D', endColorstr='#00A876', GradientType=1);
        border: #00a876;
      }
    }

    .flight-list.root-flights {
      margin-top: 2px;

      .flight-item {
        margin-bottom: 2px;

        &.whole_click {
          cursor: pointer;
        }

        .flight-box {
          border: none;

          .flight-row {
            padding: 18px 0 13px 0;

            &.more-padding {
              padding-top: 24px;
            }
          }

          .flight-airline {
            padding-left: 70px;

            .airline-logo {
              left: 24px;
            }
          }

          .flight-detail {
            width: 410px;

            .depart-box {
              width: 100px;
            }

            .time {
              font-size: 30px;
            }
          }

          .flight-detail-toggle {
            font-size: 14px;
          }

          .flight-consume {
            width: 130px;
            font-size: 14px;
          }
        }

        .taxation-tip-msg {
          text-align: right;
          position: absolute;
          right: -90px;
          width: 110px;
        }

        .flight-seats {
          .seat-row {
            padding: 10px 20px 10px 80px;
            width: 1080px;

            &.more-padding {
              padding-top: 24px;
            }
          }

          .seat-type {
            font-size: 14px;
            width: 240px;
          }

          .seat-tags {
            width: 215px;

            .tag {
              font-size: 14px;
            }
          }

          .seat-operate {
            width: 205px;

            .price-box {
              .price-b {
                font-size: 30px;
              }
            }
          }

          .seat-row-v3 .seat-info {
            .voucher,
            .rules,
            .limit {
              font-size: 14px;
            }

            .limit {
              max-width: 200px;
            }
          }

          .cabin-tabs {
            display: flex;
            height: 50px;
            padding-left: 50px;
            align-items: center;
            position: relative;
            background: #ffffff linear-gradient(-180deg, rgb(255, 255, 255) 0%, rgb(248, 250, 251) 100%);

            .tab {
              position: relative;
              cursor: pointer;
              margin-left: 25px;
              margin-right: 25px;
              font-size: 14px;
              font-weight: 500;
              color: #333333;
              height: 20px;
              letter-spacing: 0px;
              line-height: 20px;
              &.special-tab {
                margin-right: 0;
              }
            }

            &::before {
              content: '';
              height: 1px;
              background: #e4e4e4;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
            }

            .special-tab-icon {
              width: 35px;
              height: 16px;
              margin-left: 4px;
              margin-right: 25px;
              background: linear-gradient(90deg, rgb(250, 228, 180) 0%, rgb(234, 200, 136) 99.71%);
              border-radius: 8px 8px 8px 0px;
              color: #4d4339;
              font-size: 12px;
              line-height: 16px;
              text-align: center;
            }

            .tab-active {
              color: #0086f6;
            }
            .tab-active:after {
              content: '';
              position: absolute;
              bottom: -15px;
              height: 2px;
              left: 0;
              right: 0;
              background-color: #0086f6;
              border-radius: 1px;
            }
          }
        }
      }

      .recommend-box.middle {
        .four-recmd.over-four .recmd-ul > li {
          min-width: 275px;
          max-width: 275px;
        }

        .slider-box {
          width: 1114px;
        }
      }
    }
  }

  .result-header .flight-part {
    background: none;
    margin-top: 10px;

    .address,
    .lead,
    .sub-msg {
      color: #333;
    }

    .address {
      .arrive,
      .depart {
        font-size: 24px;
        font-weight: bold;
      }
    }

    .hint {
      color: #999;
    }

    .extra {
      right: 0;

      .tabs {
        margin-left: 0;
        vertical-align: unset;
      }
    }

    .airline-compare {
      line-height: 54px;
      padding-right: 35px;
    }
  }

  .flight-list .flight-item {
    margin-bottom: 1px;

    .flight-box {
      box-shadow: none;

      &.active + .flight-seats {
        overflow: hidden;
      }
    }

    .flight-seats .seat-row {
      background-color: #f8fafb;
      margin-bottom: 0;

      &.seat-row-v3 ~ .seat-row-v3::before {
        content: '';
        height: 1px;
        background: #e4e4e4;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 1130px;
        margin: 0 25px;
      }

      .brand-price-card .book-operate {
        width: 280px;
      }

      .brand-price-card {
        width: 332px;
        margin-right: 20px;
        text-align: left;

        .rest-ticket {
          left: 140px;
        }
      }
    }
  }
}

.flight-list {
  $brandPriceMaxHeight: 375px;

  line-height: 1;

  .flight-item {
    margin-bottom: 4px;

    &.no-economic-cabin {
      .flight-box {
        background-image: url(https://pic.c-ctrip.com/flight_intl/list/icons/no-ys-cabin.png);
        background-size: 80px;
        background-position: 1080px 13px;
        background-repeat: no-repeat;
      }

      &.domestic {
        .flight-box {
          background-position: 1007px 16px;
        }
      }
    }

    .flight-box {
      position: relative;
      z-index: 1;
      background-color: $white;
      padding: 0 210px 0 6px;
      border-top: 1px solid transparent;
      box-shadow: 0 1px 1px rgba(204, 204, 204, 0.5);
      transition: box-shadow 0.15s;

      &:not(.active):hover {
        box-shadow: 0 4px 15px rgba($color: #000000, $alpha: 0.18);
        z-index: 3;
      }

      &.active {
        border-top: 1px solid #dadada;
        z-index: 2;

        & + .flight-seats {
          overflow: auto;
        }
      }

      .flight-row {
        position: relative;
        padding: 18px 0 18px;
        border-top: 1px dashed $grayLightM;

        &.more-padding {
          padding-top: 24px;
        }

        &:first-child {
          border-top: none;
        }
      }

      .flight-airline {
        position: relative;
        padding-left: 50px;
        width: 210px;
        display: inline-block;
        vertical-align: middle;

        .airline-logo {
          position: absolute;
          width: 36px;
          height: 36px;
          top: 50%;
          left: 0;
          margin-top: -19px;
        }

        .airline-name {
          font-size: 14px;
          margin-bottom: 10px;
        }

        .airline-item {
          position: relative;

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          > .airline-logo {
            position: absolute;
            width: 18px;
            height: auto;
            left: -47px !important;
            top: 1px;
            margin: 0;
          }

          .airline-name {
            > .plane {
              display: inline-block;
            }
          }
        }

        .fltcard-tags {
          display: inline-block;
          vertical-align: middle;
          margin-left: 5px;
          cursor: help;
        }

        .plane {
          display: block;
          cursor: help;
          margin-top: 5px;

          &-No {
            display: inline-block;
            vertical-align: top;
            margin-right: 8px;
            padding-bottom: 2px;
            border-bottom: 1px dotted $grayDark;
          }

          &-share,
          &-multi {
            display: inline-block;
            vertical-align: top;
            margin-right: 3px;
            color: $blueLight;
          }

          &-service {
            display: inline-block;
            vertical-align: top;

            i {
              vertical-align: middle;
              margin: 0 3px;
            }
          }

          &-multi {
            margin-right: 8px;
          }
        }
      }

      .flight-detail {
        display: inline-block;
        vertical-align: middle;
        width: 360px;
        text-align: center;

        .depart-box {
          position: relative;
          display: inline-block;
          vertical-align: top;
          width: 110px;
          text-align: right;
        }

        .transfer-duration {
          color: #999999;
          font-size: 12px;
          font-weight: normal;
          display: inline-block;
          margin-bottom: 6px;
        }

        .stop-text {
          color: #999999;
          font-size: 12px;
          font-weight: normal;
          display: inline-block;
          &.high-light {
            color: #0086f6;
          }
        }

        .arrow-box {
          position: relative;
          display: inline-block;
          vertical-align: top;
          width: 120px;
          margin: 0 10px;
          padding-top: 4px;

          .remind {
            position: absolute;
            top: -15px;
            left: 0;
            width: 120px;
            text-align: center;
            color: $blueLight;
            cursor: pointer;

            &.highlight {
              color: $orangeDark;
            }
          }

          .arrow-transfer {
            line-height: 22px;
            margin-bottom: 10px;

            &.hight-light {
              color: $blueLight;
            }
          }

          .transfer-info-group {
            text-align: center;
            padding: 0 4px;
          }

          .horizontal-center {
            display: inline-block;
            text-align: left;
            max-width: 100%;
          }

          .transfer-info {
            margin-bottom: 4px;
            text-align: center;

            .high-light {
              color: #0086f6;
            }
            .diffTiminal {
              margin-top: 6px;
              color: #999;
            }

            @extend %ellipsis-block;

            i {
              color: $gray;
            }
          }
        }

        .arrive-box {
          position: relative;
          display: inline-block;
          vertical-align: top;
          width: 110px;
          text-align: left;
        }

        .time {
          position: relative;
          font-size: 26px;
          margin-bottom: 4px;
        }

        .day {
          position: absolute;
          font-size: 12px;
          color: $blueLight;
          left: 82px;
          top: -2px;
          white-space: nowrap;
          cursor: help;
        }

        .warning,
        .night-tip,
        .depart-tips {
          position: absolute;
          right: 0;
          top: -15px;
        }

        .warning {
          color: $red;
          cursor: help;

          .tag-warn {
            padding: 1px;
            margin-top: -2px;
          }
        }

        .night-tip {
          cursor: help;
          color: $blueLight;
          padding-left: 16px;

          .ico-moon {
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -7px;
          }
        }

        .depart-tips {
          .ico-moon,
          .tag-warn {
            cursor: help;
          }

          .ico-moon {
            margin-right: 5px;
          }
        }

        .airport {
          margin-bottom: 4px;

          .name {
            @extend %ellipsis;
            max-width: 83px;
          }

          .terminal {
            vertical-align: middle;
            margin-left: 2px;

            &.highlight {
              color: $blueLight;
              cursor: help;
            }
          }
        }

        .distance {
          line-height: 1.4;
          color: $gray;

          .km {
            color: $black;
          }
        }
      }

      .flight-arrival-punctuality-list {
        display: inline-block;
        color: #0086f6;
        font-size: 12px;
        width: 96px;
        text-align: right;
        padding-right: 8px;
        vertical-align: middle;

        .flight-arrival-punctuality {
          text-align: left;
          &:not(:first-child) {
            margin-top: 10px;
          }
        }
      }

      .flight-detail-toggle {
        display: inline-block;
        vertical-align: top;
        padding-top: 15px;
        width: 90px;
        text-align: center;
      }

      .flight-consume {
        display: inline-block;
        vertical-align: top;
        padding-top: 15px;
        margin-left: 5px;
        width: 75px;
        white-space: nowrap;
      }

      .flight-tags {
        display: inline-block;
        vertical-align: middle;
        width: 90px;

        .tag {
          margin: 4px;
        }
      }
    }

    .taxation-tip {
      position: absolute;
      right: 84px;
      bottom: 12px;
      color: #999;
    }

    .flight-operate {
      position: absolute;
      right: 20px;
      top: 0;
      bottom: 0;
      width: 230px;
      text-align: right;

      &:after {
        content: '';
        display: inline-block;
        width: 0;
        height: 100%;
        vertical-align: middle;
      }
    }
    &.domestic {
      .flight-operate {
        width: auto;
      }
    }

    .flight-price {
      display: inline-block;
      vertical-align: middle;
      text-align: right;

      &.domestic-flight-price {
        .price {
          color: #0086f6;
          line-height: 26px;
          font-size: 30px;

          dfn {
            color: #0086f6;
            font-size: 18px;
          }

          .qi {
            color: #0086f6;
            font-size: 12px;
          }

          &.low-price {
            .price {
              color: #ff7d13;

              dfn {
                color: #ff7d13;
              }
            }

            .qi {
              color: #ff7d13;
            }
          }
        }

        .sub-price-detail {
          color: #919ca2;
          line-height: 12px;

          .optimized-tag-container {
            background: linear-gradient(-90deg, rgba(235, 246, 255, 0.3) 0%, rgb(225, 241, 254) 100%);
            color: rgb(0, 134, 246);
            display: inline-block;
            padding: 1px 4px;
            line-height: 16px;
            font-size: 12px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            vertical-align: middle;
          }

          .sub-price-item {
            &.high-light {
              color: #ff7d13;
            }
            &.lottery {
              background: linear-gradient(-90deg, rgba(235, 246, 255, 0.3) 0%, rgb(225, 241, 254) 100%);
              line-height: 16px;
              .salePrefix {
                color: rgb(0, 134, 246);
              }
              .prefix {
                font-family: PingFangSC-Medium;
                font-weight: 500;
                color: rgb(0, 134, 246);
                display: flex;
                align-items: center;
              }
              .flightOnlineListIcon-ico_question {
                width: 16px;
                height: 16px;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: 0 0;
                background-image: url('https://pages.c-ctrip.com/flight_h5/online/list_lottery/flight-ico_question.svg');
              }
              a {
                color: inherit;
                cursor: pointer;
                display: flex;
                align-items: center;
                white-space: nowrap;
                .icon-redpack {
                  width: 10px;
                  height: 12px;
                  background-size: 100%;
                  background-repeat: no-repeat;
                  background-position: 0 0;
                  background-image: url('https://pages.c-ctrip.com/flight_h5/online/list_lottery/redpack@1.5x.png');
                }
                .flightOnlineListIcon-WebForward {
                  width: 10px;
                  height: 10px;
                  background-size: 100%;
                  background-repeat: no-repeat;
                  background-position: 0 0;
                  background-image: url('https://pages.c-ctrip.com/flight_h5/online/list_lottery/flight-web_forward.svg');
                }
                .icon {
                  margin: 0 4px;
                }
                .prefix {
                  color: rgb(51, 51, 51);
                  font-weight: normal;
                  font-family: PingFangSC-Regular;
                  display: flex;
                  justify-content: center;
                }
                .suffix {
                  color: rgb(0, 134, 246);
                  font-weight: 500;
                  font-family: PingFangSC-Medium;
                }
              }
            }

            &:not(:last-child) {
              margin-bottom: 4px;
            }

            .price {
              color: #919ca2;
              font-size: 12px;
              line-height: 12px;

              dfn {
                color: #919ca2;
              }

              .qi {
                color: #919ca2;
              }
            }
          }
        }
      }

      &.domestic-cabin-price {
        .price {
          color: #0086f6;
          font-size: 22px;
          line-height: 18px;

          dfn {
            color: #0086f6;
            font-size: 14px;
          }

          &.low-price {
            .price {
              color: #ff7d13;

              dfn {
                color: #ff7d13;
              }
            }

            .qi {
              color: #ff7d13;
            }
          }
        }

        .sub-price-detail {
          color: #919ca2;

          .optimized-tag-container {
            background: linear-gradient(-90deg, rgba(235, 246, 255, 0.3) 0%, rgb(225, 241, 254) 100%);
            color: rgb(0, 134, 246);
            display: inline-block;
            padding: 1px 4px;
            line-height: 16px;
            font-size: 12px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            vertical-align: middle;
          }

          .sub-price-item {
            line-height: 12px;

            &.high-light,
            .high-light {
              color: #ff7d13;
            }

            &.lottery {
              background: linear-gradient(-90deg, rgba(235, 246, 255, 0.3) 0%, rgb(225, 241, 254) 100%);
              line-height: 16px;
              .salePrefix {
                color: rgb(0, 134, 246);
              }
              .post-prefix {
                margin-left: 6px;
                font-size: 10px;
                line-height: 22px;
                font-family: PingFangSC-Medium;
                font-weight: 500;
                color: rgb(0, 134, 246);
                display: flex;
                justify-content: center;
              }
              .post-suffix {
                font-size: 15px;
                color: rgb(0, 134, 246);
                font-weight: 500;
                font-family: PingFangSC-Medium;
              }
              .flightOnlineListIcon-ico_question {
                margin: 4px 3px;
                width: 14px;
                height: 14px;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: 0 0;
                background-image: url('https://pages.c-ctrip.com/flight_h5/online/list_lottery/flight-ico_questionf4c0-f.svg');
              }
              a {
                color: inherit;
                cursor: pointer;
                display: flex;
                align-items: center;
                white-space: nowrap;
                .icon-redpack {
                  width: 10px;
                  height: 12px;
                  background-size: 100%;
                  background-repeat: no-repeat;
                  background-position: 0 0;
                  background-image: url('https://pages.c-ctrip.com/flight_h5/online/list_lottery/redpack@1.5x.png');
                }
                .flightOnlineListIcon-WebForward {
                  width: 16px;
                  height: 16px;
                  background-size: 100%;
                  background-repeat: no-repeat;
                  background-position: 0 0;
                  background-image: url('https://pages.c-ctrip.com/flight_h5/online/list_lottery/flight-web_forward.svg');
                }
                .icon {
                  margin: 0 4px;
                }
                .prefix {
                  color: rgb(51, 51, 51);
                  font-weight: normal;
                  font-family: PingFangSC-Regular;
                  display: flex;
                  justify-content: center;
                }
                .suffix {
                  color: rgb(0, 134, 246);
                  font-weight: 500;
                  font-family: PingFangSC-Medium;
                }
              }
            }

            &:not(:last-child) {
              margin-bottom: 4px;
            }

            .price {
              color: #0086f6;
              font-size: 12px;
              line-height: 12px;

              &.low-price {
                color: #ff7d13;

                dfn {
                  color: #ff7d13;
                }
              }

              dfn {
                color: #0086f6;
              }

              .qi {
                color: #0086f6;
              }
            }
          }
        }
      }

      .price {
        font-size: 26px;

        dfn {
          font-size: 18px;
        }

        &.over-size {
          font-size: 24px;
        }

        .qi {
          font-size: 12px;
          margin-left: 1px;
          color: $black;
        }

        .abbr {
          display: inline-block;
          border-bottom: 1px dotted $orangeDark;
          cursor: help;
        }

        &.low-price {
          color: $redDark;

          dfn {
            color: $redDark;
          }

          .abbr {
            border-color: $redDark;
          }
        }

        &.hidden {
          visibility: hidden;
        }
      }

      .tip {
        color: $gray;
        margin: 5px 0;
      }

      .taxation {
        margin: 5px 0;
      }

      .seat-type {
        display: inline-block;
        padding-bottom: 1px;

        &.highlight {
          color: $orange;
          cursor: help;
        }

        &.abbr {
          border-bottom: 1px dotted $orange;
          cursor: help;
        }
      }
    }

    .flight-action {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 80px;
      margin-left: 8px;

      .arrow-t-up-orange,
      .arrow-t-up-white,
      .arrow-t-down-orange,
      .arrow-t-down-white {
        margin: 0 0 2px 5px;
      }
    }

    .flight-exclusive {
      padding: 12px 20px 12px 60px;
      background: #f9f9f9;
      margin-bottom: 2px;

      p {
        margin: 0;
        padding: 0;
        line-height: 18px;
      }

      .link {
        margin-left: 20px;
      }

      .highlight {
        color: #00b87a;
      }
    }

    .flight-seats {
      // height: 0;
      // overflow: hidden;
      transition: height 0.4s;
      will-change: height;

      .seat-row {
        position: relative;
        padding: 10px 20px 10px 60px;
        min-height: 38px;
        background-color: #f9f9f9;
        margin-bottom: 2px;
        transition: all 0.15s;

        &.with-expand-price {
          padding: 10px 10px 10px 60px;
        }

        &:hover {
          z-index: 1;
          background-color: #ebf6ff;
          // box-shadow: 0 4px 10px rgba($color: #666666, $alpha: 0.1);
        }

        &.more-padding {
          padding-top: 18px;
          padding-bottom: 18px;
        }

        &.more-flt-row {
          padding-bottom: 10px;

          &:after {
            display: none;
          }

          .seat-operate {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        &:after {
          content: '';
          width: 0px;
          display: inline-block;
          height: 38px;
          vertical-align: middle;
        }
      }

      .seat-type,
      .seat-info,
      .seat-tags,
      .seat-operate {
        display: inline-block;
        vertical-align: middle;
        padding-right: 10px;
      }

      .seat-type {
        width: 205px;
        font-size: 12px;

        .d_product_type {
          color: #007fe9;
          border: solid 1px rgba(0, 127, 233, 0.3);
          padding: 2px 4px;
          font-size: 12px;
          border-radius: 2px;

        }

        .scattered_group {
          width: 56px;
          height: 40px;
          padding: 16px 0;
          color: rgb(51, 51, 51);
          font-family: PingFangSC-Medium;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.24px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .scattered_content {
            margin-top: 6px;
            display: flex;
            // justify-content: space-between;
            .attr {
              background: rgb(151, 151, 151);
              height: 16px;
              width: 16px;
              border-radius: 50%;
            }
            .sub_attr {
              margin-left: -4px;
              height: 16px;
              width: 16px;
            }
          }
        }

        .highlight {
          color: $orange;
          padding-bottom: 1px;

          &.abbr {
            border-bottom: 1px dotted $orange;
            cursor: help;
          }
        }

        .type,
        .special {
          display: inline-block;
          vertical-align: middle;
          margin: 4px 0;
        }

        .type {
          margin-right: 5px;
        }

        .special-img {
          display: block;
          max-width: 120px;
          cursor: help;
          margin-bottom: 3px;
          margin-right: 5px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .extData-special {
          .special-img {
            display: inline-block;
            margin-right: 5px;
          }
        }
      }

      .seat-info {
        width: 420px;
        line-height: 14px;
        color: #333;
        .limit-box {
          display: inline-block;
          margin: 4px 0;
        }
        .rules,
        .voucher,
        .luggage-rule {
          position: relative;
          display: inline-block;
          vertical-align: top;
          margin-right: 6px;
          width: 130px;
          font-size: 12px;
          padding-left: 30px;

          .highlight {
            position: absolute;
            width: 30px;
            top: 0;
            left: 0;
            color: $blueLight;
          }

          & > span {
            cursor: pointer;
          }
        }

        .voucher {
          padding-left: 0;
          width: auto;

          .abbr {
            cursor: help;
            border-bottom: 1px dotted $grayDark;
          }
        }
      }

      .seat-tags {
        position: relative;
        bottom: -5px;
        width: 120px;

        .tag {
          margin: 0 2px 5px 0;
        }
      }

      .seat-operate {
        width: 155px;
        padding-right: 0;

        .operate {
          position: relative;
          padding-right: 64px;
        }

        .seat-price {
          text-align: right;
          line-height: 1.4;
        }

        .price-box {
          display: inline-block;
          vertical-align: middle;
        }

        .price-box {
          .price {
            font-size: 18px;
            line-height: 1;
            margin-bottom: 2px;
            display: inline-block;
          }

          .price-b {
            font-size: 24px;
            line-height: 1;
            margin-bottom: 0;
            display: inline-block;
          }

          .price dfn {
            font-size: 14px;
          }

          .low-price,
          .low-price dfn {
            color: $redDark;
          }

          .tip {
            color: $gray;
            line-height: 1;
          }

          .price.abbr {
            border-bottom: 1px dotted $red;
            margin-bottom: 4px;
          }

          .low-price.abbr {
            border-bottom: 1px dotted $redDark;
          }

          .abbr {
            cursor: help;
          }
        }

        .seat-tips {
          .highlight {
            color: $greenDark;
          }

          .abbr {
            display: inline-block;
            color: $redDark;
            border-bottom: 1px dotted $redDark;
            margin-top: 2px;
            cursor: help;
          }
        }

        .seat-action {
          position: absolute;
          z-index: 1;
          right: 0;
          width: 54px;
          top: 50%;
          transform: translateY(-50%);
        }

        .reference-Sale-Price {
          color: #919ca2;
          font-family: 'PingFang SC';
          font-size: 12px;
          font-weight: normal;
          height: 12px;
          letter-spacing: 0px;
          width: 48px;
          text-decoration: line-through;
        }

        .tag-unit {
          font-size: 12px;
          font-weight: normal;
          height: 12px;
          letter-spacing: 0px;
        }

        .package-price {
          font-size: 12px;
          color: #ff6c1a;
          line-height: 12px;
          height: 12px;
          margin-top: 8px;
        }

        .btn {
          width: 52px;
          height: 28px;
          line-height: 28px;
        }

        .flt-vip-operate {
          color: #ab945f;

          .price,
          .price dfn,
          .btn {
            color: #ab954f;

            &.abbr {
              border-color: #ab945f;
            }
          }

          .btn {
            background: $white;
            filter: none;
            border-color: #ab954f;
          }
        }

        .limitations {
          color: #f00;
          text-align: right;
          line-height: 14px;
          margin-top: 6px;

          .limit {
            display: inline-block;
            text-align: justify;
            cursor: pointer;
          }
        }
      }

      .more-flt {
        position: relative;
        display: inline-block;
        padding-top: 10px;
        margin-top: 10px;
        border-top: 1px dashed $grayLight;

        .contain,
        .flt-cities,
        .flt-date,
        .flt-hover,
        .txt,
        .city,
        .arrow-gray-oneway-b {
          display: inline-block;
          vertical-align: middle;
        }

        .contain {
          color: #fff;
          background-color: #97c3ff;
          padding: 2px;
          border-radius: 2px;
          margin-right: 4px;
        }

        .arrow-gray-oneway-b {
          margin: -1px 5px 0;
        }

        .flt-date,
        .flt-hover {
          margin-left: 10px;
        }

        .flt-hover {
          color: $blueLight;
          cursor: pointer;
        }
      }

      .seat-row-v3 {
        .seat-info {
          width: 390px;

          .rules,
          .voucher,
          .limit {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
          }

          .rules {
            width: auto;
            padding-left: 0;
            color: #398efe;
            cursor: pointer;
          }

          .highlight {
            position: relative;
          }

          .spacing {
            margin: 0 3px;
            color: #e4e4e4;
          }

          .policy {
            color: #999;
            cursor: pointer;
          }

          .limit {
            max-width: 150px;
            color: #f00;
            margin-right: 0;
            cursor: default;
            line-height: 16px;
          }

          .servicePackage {
            margin-top: 19px;

            .item-icon {
              padding: 0;
              padding-right: 4px;
              height: 18px;
              display: inline-block;

              img {
                width: 18px;
                height: 18px;
                vertical-align: middle;
              }
            }

            .item-text {
              position: relative;
              top: 1px;
              color: #225885;
              margin-right: 7px;
            }
          }
        }

        .seat-operate {
          width: 185px;
          position: relative;

          .operate {
            padding-right: 75px;
            .debug-tag {
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              img {
                width: 50px;
                height: 50px;
              }
            }
          }

          .seat-action {
            white-space: nowrap;
            width: 70px;
          }

          .tag-less {
            position: initial;
            margin-left: 4px;
            border: none;
            background-color: transparent;
          }
        }

        &.has-related-price {
          text-align: right;
        }

        .brand-price-card {
          width: 292px;
          display: inline-block;
          min-height: $brandPriceMaxHeight;
          margin-right: 5px;
          vertical-align: top;
          box-shadow: 0 2px 6px 0 #e4e4e4;
          border-radius: 8px;
          background: #fff;
          position: relative;
          vertical-align: top;

          &.no-min-height {
            min-height: 50px;

            .brand-price-card-content {
              min-height: 50px;
              padding: 16px 10px 30px 24px;
            }
          }

          &.compact-mode {
            width: 250px;
            margin-right: 13px;
          }

          &:last-child {
            margin-right: 0;
          }

          .abbr {
            border-bottom: 1px dotted #f30000;
            cursor: help;
          }

          .brand-price-card-title {
            height: 57px;

            &::after {
              content: '';
              width: 80%;
              border-top: dashed 1px #e4e4e4;
              height: 1px;
              display: inline-block;
              margin: 0 10%;
            }

            .main-card-title {
              font-size: 18px;
              text-align: center;
              padding: 10px 0 4px 0;

              .high-light {
                color: #0086f6;
              }
              .ins-version {
                margin-left: 8px;
                color: #999999;
                font-size: 14px;
              }
            }

            .sub-card-title {
              font-size: 12px;
              text-align: center;

              &.limited-title {
                color: #999;
              }

              &.good-title,
              &.medium-title {
                color: #00b780;
              }

              &.bad-title {
                color: #999;
              }
            }

            .tag-advertisement {
              border-top-left-radius: 6px;
            }
          }

          .brand-price-card-content {
            min-height: 200px;
            padding: 26px 10px 30px 24px;
          }

          .brand-price-entry {
            margin: 0;
            padding: 0 0 8px 0;

            .brand-price-icon {
              width: 12px;
              height: 12px;
              display: inline-block;
              vertical-align: top;
            }

            .brand-entry-text {
              font-size: 12px;
              text-overflow: ellipsis;
              overflow: hidden;
              margin-left: 5px;
              line-height: 14px;
            }

            &.brand-entry-good {
              .brand-price-icon {
                background: url(https://pic.c-ctrip.com/flight_intl/list/icons/thumb-up.png);
              }
            }

            &.brand-entry-medium {
              .brand-price-icon {
                background: url(https://pic.c-ctrip.com/flight_intl/list/icons/tick.png);
                height: 10px;
              }
            }

            &.brand-entry-bad {
              .brand-price-icon {
                background: url(https://pic.c-ctrip.com/flight_intl/list/icons/exclamation.png);
              }

              .brand-entry-text {
                color: #999;
              }
            }
          }

          .brand-price-container {
            position: absolute;
            bottom: 60px;
            width: 265px;
            display: table;
          }

          .brand-price-box {
            display: inline-block;
            vertical-align: bottom;
          }

          .brand_price_tags_root {
            display: inline-block;
            position: absolute;
            margin-left: 7px;
            bottom: 0;

            &.contains_tax {
              margin-top: -10px;
            }

            &.not_contains_tax {
              margin-top: 10px;
            }
          }

          .brand-price-tax {
            color: #999;
            line-height: 18px;
            margin-bottom: 2px;
          }

          .brand-price-amount {
            color: #ff6c1a;
            font-size: 24px;

            dfn {
              font-size: 14px;
            }
          }

          .brand-price-tags {
            width: 180px;
            top: 2px;
            line-height: 20px;
            display: table-cell;
            vertical-align: bottom;

            .tag {
              margin-right: 2px;
            }
          }

          .book-operate {
            position: absolute;
            bottom: 23px;
            width: 240px;
            height: 30px;
            line-height: 30px;
          }

          .rest-ticket {
            color: #e40000;
            position: absolute;
            bottom: 5px;
            left: 120px;
          }
        }

        &.with-expand-price .seat-operate {
          .seat-action {
            width: 55px;
          }

          .tag-less {
            position: absolute;
            border: solid 1px #e10022;
            background: #fff;
            padding: 2px;
            top: -12px;
            right: -10px;
          }

          .operate {
            padding-right: 60px;
          }

          .btn {
            width: 62px;
          }
        }
      }
    }

    .tag-advertisement,
    .tag-light-blue {
      position: absolute;
      left: 0;
      top: 0;
      cursor: help;
    }

    .tag-advertisement {
      height: 20px;
    }

    .tag-marketing {
      position: absolute;
      left: -10px;
      top: 0;
    }

    .tag-light-blue {
      line-height: 20px;
      height: 20px;
      color: $white;
      padding-left: 10px;
      background-image: linear-gradient(to right, #72d9ff, #30c7ff);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#72D9FF', endColorstr='#30c7ff', GradientType=1);

      i {
        position: absolute;
        right: -21px;
        top: 0;
      }
    }

    .tab-box {
      position: absolute;
      left: 0;
      top: 0;
      margin-left: -6px;

      .tag-marketing {
        position: relative;
        display: inline-block;
        left: auto;
        top: auto;
        z-index: 1;

        & + .tag-light-orange {
          margin-left: -10px;
          padding-left: 38px;

          &::before {
            left: 20px;
          }
        }
      }

      .tag-light-orange {
        position: relative;
        display: inline-block;
        line-height: 20px;
        height: 20px;
        color: $white;
        padding-left: 28px;
        background-image: linear-gradient(to right, #ff9913, #ff7717);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF9913', endColorstr='#FF7717', GradientType=1);

        &::before {
          content: '';
          position: absolute;
          left: 10px;
          top: 3px;
          width: 12px;
          height: 12px;
          background: url(https://pic.c-ctrip.com/flight_intl/list/gift.svg) no-repeat;
          background-size: 100%;
        }

        i {
          position: absolute;
          right: -21px;
          top: 0;
        }
      }
    }
  }

  .flight-train {
    border: 1px solid $orange;
    margin-bottom: 4px;

    .flight-box {
      padding: 0 209px 0 9px;
    }

    .flight-item:last-child {
      margin-bottom: 0;
    }

    .flight-seats {
      .seat-row {
        padding: 22px 19px 22px 59px;
      }
    }
  }

  .new-airport-icon {
    display: inline-block;
    background: #f70;
    font-size: 10px;
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    color: #fff;
    padding: 1px 2px 3px;
    border-radius: 2px;
    vertical-align: middle;
    cursor: help;
  }

  .new-airport {
    color: #f70;
  }

  //东航品牌运价动画效果
  .brand-price-enter {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 0;
    transition: all 200ms ease !important;
    overflow: hidden !important;
  }

  .brand-price-enter.brand-price-enter-active {
    max-height: $brandPriceMaxHeight;
  }

  .brand-price-leave {
    max-height: $brandPriceMaxHeight;
    opacity: 1;
    transition: all 200ms ease-out !important;
    min-height: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    overflow: hidden !important;
  }

  .brand-price-leave.brand-price-leave-active {
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  //航班加载动画效果
  .flights-slide-down-enter,
  .flights-slide-down-appear {
    transform: scaleY(0.8);
    transform-origin: center top;
    transition: transform 0.2s ease-in;
  }

  .flights-slide-down-enter.flights-slide-down-enter-active,
  .flights-slide-down-appear.flights-slide-down-appear-active {
    transform: scaleY(1);
  }
}

.seat-type {
  .type.lcc-service-tag {
    color: #ff9913;
    border-bottom: 1px dashed;
    padding-bottom: 1px;
  }
}

.more-result {
  background-color: $white;
  margin-bottom: 6px;
  text-align: center;

  .trigger {
    display: block;
    font-size: 14px;
    line-height: 40px;
  }

  .arrow-down-bold,
  .arrow-up-bold {
    margin-left: 5px;
    margin-bottom: 2px;
  }
}

.brand-hover-root {
  max-width: 210px;
  position: relative;
  top: -20px;

  .brand-hover {
    position: relative;
    bottom: -18px;
    padding: 14px 20px;
    background: #fff;
    width: 100%;
  }

  .tooltip {
    width: 100%;
  }
}

.filter-v2 {
  .fake-flights {
    position: relative;
  }

  @keyframes fake-flights-bling {
    0% {
      opacity: 0.2;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.2;
    }
  }

  .fake-flight-summary > div,
  .fake-flight-price > div {
    background: #eef1f6;
    display: inline-block;
    position: absolute;
    animation: fake-flights-bling 2s ease infinite;
  }

  .fake-flight-summary {
    height: 80px;
    background: #fff;
    position: relative;

    .r0c0 {
      width: 40px;
      height: 40px;
      left: 24px;
      top: 20px;
    }

    .r0c1 {
      width: 80px;
      height: 20px;
      left: 80px;
      top: 20px;
    }

    .r1c1 {
      width: 80px;
      height: 10px;
      left: 80px;
      top: 50px;
    }

    .r0c2 {
      width: 294px;
      height: 20px;
      left: 296px;
      top: 20px;
    }

    .r0c3 {
      width: 80px;
      height: 20px;
      top: 30px;
      left: 690px;
    }

    .r0c4 {
      width: 80px;
      height: 20px;
      top: 30px;
      left: 848px;
    }

    .r0c5 {
      width: 80px;
      height: 20px;
      top: 30px;
      left: 1006px;
    }
  }

  .fake-flight-price {
    background: #f8fafb;
    height: 80px;
    position: relative;

    &:after {
      content: '';
      border-top: 1px solid #e4e4e4;
      display: block;
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 15px;
    }

    .r0c0 {
      width: 163px;
      height: 20px;
      left: 80px;
      top: 30px;
    }

    .r0c1 {
      width: 295px;
      height: 20px;
      left: 295px;
      top: 30px;
    }

    .r0c2 {
      width: 80px;
      height: 20px;
      left: 1004px;
      top: 30px;
    }
  }
}

.flt-detail .arrive-box .day {
  position: relative;
  font-size: 12px;
  color: rgb(57, 142, 254);
  right: -130px;
  top: -40px;
  white-space: nowrap;
  cursor: help;
}

.custom-srcoll-bar {
  @extend %scroll-bar;
}

.ngs-root {
  display: inline-block;
}

.ngs-label {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  background: #0086f6;
  border-radius: 9px;
  padding: 2px 8px;
  cursor: pointer;

  .current-score {
    color: #fff;
  }

  .total-score {
    color: #98cdfa;
  }
}

.ngs-content {
  width: 280px;
  padding: 16px;
  background: #fff;
  box-shadow: 6px 5px 9px 1px rgba(0, 0, 0, 0.2);
  margin-top: 3px;
  margin-left: -5px;

  .score-header {
    font-size: 20px;
    color: #0086f6;
  }

  .header-total-score {
    font-size: 14px;
    color: rgb(133, 183, 228);
    margin-right: 5px;
  }

  .score-remark {
    margin-top: 14px;
    line-height: 17px;
  }

  .ngs-logo {
    position: absolute;
    right: 22px;
    top: 30px;
    background: url(//pic.c-ctrip.com/flight_intl/list/icons/ngs.png);
    background-size: contain;
    display: inline-block;
    height: 19px;
    width: 64px;
  }
}

.zelda_color {
  .calendarlp-tab {
    .tabs {
      ul {
        .tab {
          .price {
            color: #0086f6;

            dfn {
              color: #0086f6;
            }
          }

          .lowprice {
            color: #ff6600;

            dfn {
              color: #ff6600;
            }
          }

          .tag-lowprice {
            background-color: #ff6600;

            dfn {
              color: #ff6600;
            }
          }

          &.active {
            div.date,
            span.hint,
            div.price,
            span.holiday,
            div div.date,
            div.price dfn {
              color: #fff;
            }

            div.link {
              color: #fff;
            }

            div.lowprice {
              color: #fff;

              dfn {
                color: #fff;
              }

              .tag-lowprice {
                background-color: #ff6600;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .flight-list {
    .flight-item {
      .flight-box {
        .flight-airline {
          .plane-No {
            color: #0086f6;
            border-bottom: none;

            .high-light {
              color: #00b87a;
            }
          }
        }
      }

      .flight-seats {
        .seat-info {
          .rules {
            .highlight {
              color: #398efe;
            }
          }
        }

        .seat-tags {
          .tag {
            color: #00b87a;
            border: 1px solid #bfeddd;
          }
        }

        .seat-operate {
          .package-price {
            color: #00a876;
          }

          .price-box {
            .price {
              color: #0086f6;

              dfn {
                color: #0086f6;
              }
            }

            .low-price {
              color: #ff6600;

              dfn {
                color: #ff6600;
              }
            }
          }

          .book-tooltip {
            visibility: visible;
            display: inline-block;
            z-index: 10;
            position: relative;
            right: 64px;
            bottom: 24px;
            padding: 2px;
            width: 38px;
            line-height: 12px;
            background-color: #ff7528;
            color: #fff;
            font-size: 12px;
            text-align: center;
            border-radius: 2px 2px 0 2px;
            -webkit-text-size-adjust: none;
            transform: scale(0.83, 0.83);

            span {
              z-index: 10;
              overflow: hidden;
              position: absolute;
              width: 0;
              height: 0;
              font-size: 0;

              &.tooltip-tail {
                z-index: -2;
                bottom: -6px;
                left: 30px;
                border-width: 6px;
                border: 6px solid transparent;
                border-right-color: #ff7528;
              }

              &.tooltip-tail-bottom {
                z-index: -4;
                bottom: -9px;
                left: 29px;
                border-width: 7px;
                border: 7px solid transparent;
                border-right-color: #fff;
              }
            }
          }
        }

        .tag-container {
          display: inline-block;
          color: #007fe9;
          border: 1px solid rgba(0, 127, 233, 0.3);

          .special-tag {
            display: inline-block;
            height: 16px;
            line-height: 16px;
            text-align: center;

            span {
              padding: 0 2px 0 4px;
              font-size: 12px;
              text-align: center;

              &.tag-text {
                background: none;
              }

              &.tag-bg-text {
                background-color: #d1e3fb;
              }

              &.tag-icon {
                -webkit-text-size-adjust: none;
                transform: scale(0.83, 0.83);
              }
            }
          }

          &.business {
            color: #e3be8d;
            border-color: #e0b072;

            span {
              &.tag-bg-text {
                background-color: #f8eee2;
              }
            }
          }

          &.round-corner {
            border-radius: 2px;

            span {
              &.tag-bg-text {
                border-radius: 2px;
              }
            }
          }
        }
      }

      .flight-seats {
        .seat-row-v3 {
          .seat-info {
            .limit {
              color: #ff6600;
            }
          }
        }
      }
    }
  }

  .calendarlp-main {
    .cld-body {
      .table {
        .td:not(.active):hover {
          background-color: #ebf6ff;

          .date,
          .detail {
            color: #0086f6;
          }

          .price {
            color: #0086f6;

            dfn {
              color: #0086f6;
            }

            &.lowprice {
              color: #ff6600;

              dfn {
                color: #ff6600;
              }
            }
          }
        }
        .td {
          &.active {
            &:hover {
              .price {
                height: 14px;
                line-height: 14px;
              }
            }
            .tag-lowprice {
              background-color: #f60;
              color: #fff;
            }
          }
        }

        .tag-lowprice {
          margin: -2px 0 0 3px;
          background-color: #ff6600;
        }

        .price {
          &.lowprice {
            color: #ff6600;

            dfn {
              color: #ff6600;
            }
          }
        }
      }

      .cld-new {
        .table {
          .td:not(.forbid):hover {
            .date,
            .detail {
              color: #0086f6;
            }

            .price {
              color: #0086f6;

              dfn {
                color: #0086f6;
              }

              &.lowprice {
                color: #ff6600;

                dfn {
                  color: #ff6600;
                }
              }
            }
          }

          .td.active:hover {
            .date,
            .detail {
              color: #fff;
            }

            .price {
              color: #fff;

              dfn {
                color: #fff;
              }
            }
          }
          .td.active:not(.forbid):hover {
            .price {
              color: #fff;
            }
            .price.lowprice {
              dfn {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .airline-price {
    .airline-tbl {
      .detail {
        .price.lowprice {
          color: #ff6600;

          dfn {
            color: #ff6600;
          }
        }

        .price:not(.no-price):hover {
          background-color: #398eff1a;
          color: #0086f6;

          dfn {
            color: #0086f6;
          }
        }
      }
    }
  }
}

@keyframes fake-bling {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

%fake-root {
  position: relative;
  animation: fake-bling 2s ease infinite;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > div {
    background: #eef1f6;
    display: inline-block;
    position: absolute;
  }
}

.fake-lowprice-calendar {
  background: white;
  padding: 5px 0;
  margin-top: -10px;

  .content {
    @extend %fake-root;
    margin: 0 auto 0 auto;
    width: 1180px;
    height: 72px;

    & > div {
      top: 0;
      bottom: 0;
    }

    .arrow {
      width: 60px;

      &.left {
        left: 0;
      }

      &.right {
        right: 140px;
      }
    }

    .more {
      right: 0;
      width: 115px;
    }

    .date {
      width: 80px;
      $init: 90px;
      $delta: 130px;

      &.c0 {
        left: $init;
      }

      &.c1 {
        left: $init + $delta;
      }

      &.c2 {
        left: $init + $delta * 2;
      }

      &.c3 {
        left: $init + $delta * 3;
      }

      &.c4 {
        left: $init + $delta * 4;
      }

      &.c5 {
        left: $init + $delta * 5;
      }

      &.c6 {
        left: $init + $delta * 6;
      }
    }
  }
}

.fake-search-box {
  @extend %fake-root;
  margin: 55px auto 0 auto;
  width: 1180px;
  height: 110px;

  .r0c0 {
    top: 6px;
    left: 0;
    width: 53px;
    height: 20px;
  }

  .r0c1 {
    top: 6px;
    left: 79px;
    width: 53px;
    height: 20px;
  }

  .r0c2 {
    top: 6px;
    left: 161px;
    width: 150px;
    height: 20px;
  }

  .r0c3 {
    top: 6px;
    right: 0;
    width: 127px;
    height: 20px;
  }

  .r1c0 {
    top: 43px;
    left: 0;
    width: 270px;
    height: 62px;
  }

  .r1c1 {
    top: 43px;
    left: 280px;
    width: 270px;
    height: 62px;
  }

  .r1c2 {
    top: 43px;
    left: 590px;
    width: 160px;
    height: 62px;
  }

  .r1c3 {
    top: 43px;
    left: 760px;
    width: 170px;
    height: 62px;
  }

  .r1c4 {
    top: 43px;
    right: 0;
    width: 210px;
    height: 62px;
  }
}

.expand-default-collapse-price {
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  background: #f8fafb;
  margin-top: 1px;
  color: #666666;
  cursor: pointer;

  &:hover {
    background-color: #eef6ff;
    box-shadow: 0 4px 10px hsla(0, 0%, 40%, 0.1);
  }

  &::after {
    content: '';
    display: inline-block;
    margin: 0 3px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    line-height: 0;
    vertical-align: 1px;
  }

  &.up::after {
    border-bottom: 5px solid #0086f6;
  }

  &.down::after {
    border-top: 5px solid #0086f6;
  }
}
.filter-v2 .result-wrapper {
  .flight-list {
    .flight-item {
      .flight-action {
        width: auto;
      }
      &.domestic {
        .flight-tags {
          width: 160px;
          text-align: right;
          display: inline-block;
          vertical-align: middle;
          .optimized-tag-container {
            background: linear-gradient(-90deg, rgba(235, 246, 255, 0.3) 0%, rgb(225, 241, 254) 100%);
            color: rgb(0, 134, 246);
            display: inline-block;
            padding: 1px 4px;
            line-height: 16px;
            font-size: 12px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            vertical-align: middle;
          }
          .tag {
            color: #00b87a;
            border: 1px solid #bfeddd;
            display: inline-block;
            border-radius: 2px;
            padding: 1px 4px 2px;
            line-height: 12px;
            font-size: 12px;
            vertical-align: middle;
          }
          .member-level {
            border-color: #b6a68d;
            color: #6d4e1b;
          }
          .member-level-black {
            background: linear-gradient(90deg, rgb(102, 102, 102) 0%, rgb(51, 51, 51) 100%);
            box-shadow: 0px 6px 16px 2px rgba(0, 0, 0, 0.08);
            border: none;
            padding: 1px 4px 2px;
            color: rgb(245, 215, 166);
          }
          .member-super {
            border-color: #d6bdb4;
            color: #7b3e29;
          }
        }
        .flight-tip {
          padding: 12px 76px;
          color: #666666;
          background: #fff8f2;
          font-size: 12px;
          line-height: 18px;
          position: relative;
          &::before {
            position: absolute;
            display: block;
            content: '';
            height: 15px;
            width: 15px;
            margin-left: -19px;
            margin-top: 2px;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABvUlEQVQ4T6XTO2hUQRTG8d+JJhBQMJUPBFELhaCIYimCwUYxICTYCEFQsVBEJLs+CrdRvDeojYWEFFHRJlqIhTZpgpXgAxFFEBZBiRbCFoEUIRnJhht2s7HRgSnmzDl/zjfnm/CfK5bWp0HrtDkv2YsOYS31/dic4RjyvrGmCZBKBoTjuKvTeFRMzSenilWm9Uiu4EXkKgVkEVAv5qCqgRgzWy8sO2VOiiEj9XO/FTa7L3yITD4fqwNSyQY8UbM/hs0U9FQyIcxEpmcxdlq7Lm8k/ZH7UgBGhGeRed6oL5W8xHTkjjbFy46gLzIDBeCrqm1F6w0dPBWm5hObAAtSvkdufaQLOrV7HbkdLRMpeyipRe7cMncfhX2RLumSvIpM9zJJ9/A7Mldb7ko+mbGnkDCpamOLhLLbkl+Ry5okVHSY9jkyWwvAA2Gs5REv2lQf1S3fljxuL3ojd3IBcNl2s8bU7G4aY9lbrIzMzqYxrjGhXV9c96PRSGV0qzrRYKTDaCs6qxtpi1FhPG4aXTRSw9iu4ZBwo8nKJauFAzgrPCqKWwB1OYN2aXNGcgyTqGFKeGfOnRjy86+f6V9+9h/hrZ8S+thkzAAAAABJRU5ErkJggg==');
            background-size: 100% 100%;
          }
        }
        .btn.btn-book {
          width: auto;
          padding: 0 9px;
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          font-weight: 600;
          .arrow-t-down-white {
            width: 10px;
            height: 10px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA90lEQVQ4T82TsSuGURjFf7+kpCSbslmN/gApRH2rMhhs8tcYbcbPoCwsopSsFsVoMCil1JekpKNX99OLl+Vdvrvcus/znM45z7nS8thyngEBSDIHzAB76tN/spJMAGvAtXr+KSFJDxgDboF59a4JJMk0cAJUd08d7wOsA7vAMPAILKuXdZAks2W4YvAGbKjdLxOLjMPC5BVYVY8Kww6wD4wAz8CKelHVvm0hSeXDMTBVKQM2gVFgu/TeA4vqTZ/drzUmmQROi6l1FVfAkvpQf2zMQZLK0ANgoTSfAR315ae5fwYpyRCwVQZ21PemzQxIEtt8qNYSPgBdp0YRbkAwowAAAABJRU5ErkJggg==');
          }
          .arrow-t-up-orange {
            width: 10px;
            height: 10px;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABNElEQVQ4T83Tv0uCURTG8e+5r1GQZa8OQaOjS0NzgxDUUEtQQ0FL+PYL+mOCMrOlIYjapM0QormhwbUhCBrMN5WgyOuJXiu0JAIX73jvPR/OczlX6HJJl/X0MKCnOPixjSCi+5iWRWynuB0j6C5hQrEckGwW6QUhMy+rpdpP5Beg2cFRbH8BkUT7ZS1Sf52SreeH1v02QPejCUTywBiogmx/drADIqjeY5gWr1z8Qr4BzYwkUSeHEAZekMaCeP55EODAnUXNGTAA1BA7J97T5cdZAGjaXcbIEYgDWsLYGUlVrltb1WxkAuvkEXFB3xBdEc8/aQKZWBUYQvUW0aSs+XedXlz3InFMgMRRrcp6OdIEsu4kDTOOrR/LZsX/azr1cDiK7VvCNG4k5V/18CT+95N1HeEdUr1jEWRRqykAAAAASUVORK5CYII=');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: 0 0;
          }
        }
      }
      .flight-seats {
        .seat-row-v3 {
          // 国内运价样式

          &.seat-row-domestic {
            > div {
              vertical-align: middle;
            }
            &.has-ticket-count-label {
              padding-top: 14px;
            }
            .seat-info {
              * {
                font-size: 12px;
              }
              > div {
                line-height: 16px;
              }
              .rules {
                .highlight {
                  &.warn {
                    color: #ff7700;
                  }
                  &.strong {
                    font-weight: 600;
                  }
                }
              }

              width: 307px;
              margin-right: 0;
              padding-right: 0;
              .limit-box {
                &.limit-box-block {
                  display: block;
                  margin-top: 8px;
                }
                display: block;
                .limit {
                  border-radius: 2px;
                  border: 1px solid rgba(255, 119, 0, 0.3);
                  color: #ff7700;
                  padding: 1px 4px;
                  line-height: 12px;
                  &:not(:last-child) {
                    margin-right: 4px;
                  }
                }
                .ap-discount {
                  color: #ff7700;
                }
              }
              .servicePackage {
                margin-top: 12px;
                .item-icon {
                  img {
                    width: 16px;
                    height: 16px;
                    vertical-align: middle;
                  }
                }
                .item-text {
                  font-size: 12px;
                  line-height: 12px;
                  color: #5a3a23;
                }
              }
            }
            .domestic-seat-type {
              display: inline-block;
              width: 160px;
              .domestic-cabin-item {
                margin: 4px 0px;
                line-height: 16px;
                vertical-align: top;
                text-align: left;
                word-break: keep-all;
                white-space: nowrap;
                &:not(:first-child) {
                  margin-top: 8px;
                }
                .seatType_prefix {
                  margin-right: 6px;
                }
                .domestic-special-cabin {
                  color: #3a84ee;
                }
              }
            }
            .seat-tags {
              width: 150px;
              padding-right: 0;
              text-align: right;
              position: static;
              .tag {
                font-size: 12px;
                padding: 1px 4px 2px;
                border-radius: 2px;

                .pre_label::after {
                  content: '';
                  width: 1px;
                  display: inline-block;
                  background: rgba(0, 168, 118, 0.3);
                  height: 10px;
                  vertical-align: middle;
                  margin: 0 2px;
                }
              }
              .member-level {
                border-color: #b6a68d;
                color: #6d4e1b;
              }
              .member-level-black {
                background: linear-gradient(90deg, rgb(102, 102, 102) 0%, rgb(51, 51, 51) 100%);
                box-shadow: 0px 6px 16px 2px rgba(0, 0, 0, 0.08);
                border: none;
                padding: 1px 4px 2px;
                color: rgb(245, 215, 166);
              }
              .member-super {
                border-color: #d6bdb4;
                color: #7b3e29;
              }
            }
            .seat-operate {
              width: 212px;
              .operate {
                padding-right: 76px;
              }
              .seat-action {
                width: auto;
                text-align: right;
                .book-tooltip {
                  position: absolute;
                  right: 19px;
                  top: -17px;
                  bottom: auto;
                }
                .btn {
                  width: auto;
                  min-width: 40px;
                  float: right;
                }
                .tag-less {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.tooltip {
  .new-airport-tit {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .new-airport-con {
    color: #999;
  }
}
.domestic-svc-detail-box {
  padding: 32px 8px 10px 24px;
  width: 638px;
  height: 452px;
  box-shadow: 0 4px 8px hsla(0, 0%, 80%, 0.8);
  border-radius: 4px;
  > h2 {
    margin-bottom: 16px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #333;
    line-height: 28px;
  }
  .detail-contend {
    height: 408px;
    .detail-item {
      padding: 0 12px 0 0;
      .item-desc-title {
        padding: 16px 0 0 22px;
        background-image: url(https://dimg04.c-ctrip.com/images/0AS05120008ajt2qm31D9.png);
        background-size: 18px 13px;
        background-repeat: no-repeat;
        background-position: 0px 21px;
        height: 22px;
        line-height: 22px;
        color: #333;
        text-align: left;
        font-weight: 600;
        margin-top: 16px;
        border-top: 1px solid #eeeeee;
      }
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      .detail-item-text {
        vertical-align: middle;
        color: #333333;
        height: 22px;
        font-size: 14px;
        > .text {
          vertical-align: middle;
          font-weight: 600;
        }
        .detail-item-icon {
          position: static;
          > img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
          }
        }
      }
      .detail-item-desc {
        color: #333333;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
      }
    }
  }

  .detail-item-desc {
    color: #333;
    line-height: 22px;
    font-size: 14px;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}


.debug-info-box {
  width: 600px;
  padding: 20px;
  position: relative;
  background: #fff;
  margin: 0 auto;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 20px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 6px 20px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  word-break: break-all;
  .debug-info {
    font-size: 15px;
    margin-bottom: 10px;
  }
}