@import 'common';
.loading-container{
    background-color: $lightGray;
    margin-top: -54px;
    padding: 34px 0 30px 0;
    .top{
        height: 143px;
        background-color: #fff;
        padding: 44px 0 8px 0;
        margin-bottom: 16px;
        margin-top: -34px;
        .searchBox-container{
            width: 1224px;
            margin: 0 auto;
        }
    }
    .loading-container-content{
        margin: 0 auto;
        width: 1180px;
        .calender{
            height: 72px;
            @extend %loadingContent;
            position: relative;
            margin-bottom: 20px;
        }
        .flight-text{
            height: 33px;
            padding: 10px 10px;
            position: relative;
            // background-color: $white;
            .left{
                @extend %loadingContent;
                height: 33px;
                width: 370px;
                left: 0;
                background-color: $darkGray;

            }
            .right{
                @extend %loadingContent;
                height: 14px;
                width: 370px;
                padding: 0;
                right: 0;
                top: 17px;
                background-color: $darkGray;

            }

        }
        .search-result{
            .filter{
                @extend %loadingContainer;
                padding: 16px 16px 16px 24px;
                height: 32px;
                background-color: $white;
                border-radius: 8px 8px 0 0;
                margin-bottom: 4px;
                > ul{
                    margin: 0;
                    padding: 0;
                    &.left{
                        position: absolute;
                        width: 730px;
                        left: 16px;
                    }
                    &.right{
                        position: absolute;
                        width: 500px;
                        right: 0;
                        li{
                            margin-left: 16px;
                        }
                    }
                    li{
                        height: 32px;
                        display: inline-block;
                        width: 65px;
                        height: 32px;
                        @extend %lightLoadingContainer;
                        margin-right: 16px;
                    }
                }
            }
            .result{
                background-color: $white;
            }

    .fake-flight-summary>div,
    .fake-flight-price>div {
        background: $darkGray;
        display: inline-block;
        position: absolute;
        animation: fake-flights-bling 2s ease infinite;
    }

    .fake-flight-summary {
        height: 80px;
        background: #fff;
        position: relative;

        .r0c0 {
            width: 40px;
            height: 40px;
            left: 24px;
            top: 20px;
        }

        .r0c1 {
            width: 80px;
            height: 20px;
            left: 80px;
            top: 20px;
        }

        .r1c1 {
            width: 80px;
            height: 10px;
            left: 80px;
            top: 50px;
        }

        .r0c2 {
            width: 294px;
            height: 20px;
            left: 296px;
            top: 20px;
        }

        .r0c3 {
            width: 80px;
            height: 20px;
            top: 30px;
            left: 690px;
        }

        .r0c4 {
            width: 80px;
            height: 20px;
            top: 30px;
            left: 848px;
        }

        .r0c5 {
            width: 80px;
            height: 20px;
            top: 30px;
            left: 1006px;
        }
    }

    .fake-flight-price {
        background: #f8fafb;
        height: 80px;
        position: relative;

        &:after {
            content: "";
            border-top: 1px solid #e4e4e4;
            display: block;
            position: absolute;
            bottom: 0;
            left: 15px;
            right: 15px;
        }

        .r0c0 {
            width: 163px;
            height: 20px;
            left: 80px;
            top: 30px;
        }

        .r0c1 {
            width: 295px;
            height: 20px;
            left: 295px;
            top: 30px;
        }

        .r0c2 {
            width: 80px;
            height: 20px;
            left: 1004px;
            top: 30px;
        }
    }

        }
    }



}
