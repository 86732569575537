// 运价未展开时显示票余量
.flight-action {
    .book-tooltip {
        visibility: visible;
        display: inline-block;
        z-index: 10;
        position: absolute;
        //position: relative; 列表展开时的样式
        //right: 64px; 列表展开时的样式
        right: 50%;
        bottom: 36px;
        //bottom: 24px; 列表展开时的样式
        padding: 2px;
        width: 38px;
        line-height: 12px;
        background-color: #ff7528;
        color: #fff;
        font-size: 12px;
        text-align: center;
        border-radius: 2px 2px 0 2px;
        -webkit-text-size-adjust: none;
        transform: scale(0.83, 0.83) translateX(50%);
        //transform: scale(0.83, 0.83); 列表展开时的样式

        span {
            z-index: 10;
            overflow: hidden;
            position: absolute;
            width: 0;
            height: 0;
            font-size: 0;

            &.tooltip-tail {
                z-index: -2;
                bottom: -6px;
                left: 30px;
                border-width: 6px;
                border: 6px solid transparent;
                border-right-color: #ff7528;
            }

            &.tooltip-tail-bottom {
                z-index: -4;
                bottom: -9px;
                left: 29px;
                border-width: 7px;
                border: 7px solid transparent;
                border-right-color: #fff;
            }
        }
    }
}

.flight-price {
    span {
        &.reference-Sale-Price {
            color: #919ca2;
            font-family: "PingFang SC";
            font-size: 12px;
            font-weight: normal;
            height: 12px;
            letter-spacing: 0px;
            width: 48px;
            text-decoration: line-through;
        }

        &.tag-Macau-buyOneGetOne {
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
            font-size: 12px;
            border: 1px solid #bfeddd;
            color: #00b87a;
            padding: 1px 3px 2px;
            border-radius: 2px;
            white-space: nowrap;
            overflow: hidden;
            cursor: help;
            height: auto;
            margin: 0;
        }
        &.tag-Macau-buyOneGetOne:hover{
            cursor: help;
        }
    }
    div {
        &.price-verticle {
            display: flex;
            flex-direction: column;
            align-items:flex-end;
        }
    }
    .c-react-tip {
        width: 20em;
        text-align: left;
        line-height: 1.5em
    }
}
