%ico {
    display: inline-block;
    vertical-align: middle;

    background-image: url(https://pic.c-ctrip.com/flight_intl/list/ico_sprite.png?v6);
    background-image: -webkit-image-set(url(https://pic.c-ctrip.com/flight_intl/list/ico_sprite.png?v6) 1x,url(https://pic.c-ctrip.com/flight_intl/list/ico_sprite@2x.png?v6) 2x);
    background-repeat: no-repeat;
}

.ico-attention{
    @extend %ico;
    background-position: -20px 0;
    width: 14px;
    height: 14px;
}
.notice-board{
    box-sizing: border-box;
    margin: 0 auto 6px;
    position: relative;
    color: #6c8291;
    padding: 12px 48px 12px 16px;
    background: #fff1f0;
    border-radius: 2px 2px 2px 2px;
    line-height: 22px;
    width: 1180px;
    padding-left: 37px;

    &:not(:last-child){
        margin-bottom: 6px;
    }
    &.fold{
        .content{
            max-height: 22px;
            text-overflow:ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    .fold-ctrl{
        position: absolute;
        top: 11px;
        right: 16px;
        cursor: pointer;

    }
    .iconf-up, .iconf-down{
        color:#999;
    }
    .notice-type{
        float: left;
        margin-right: 5px;
    }
    .content{
        >h4, >p{
            display: inline;
        }
    }


    .notices{
        overflow: hidden;
        .notice{ margin-bottom: 5px; }
    }

    .ico-attention{
        position: absolute;
        left: 20px;
        top: 16px;

    }
    .ico-close{
        position: absolute;
        right: 20px;
        top: 13px;
        cursor: pointer;
    }

    &.lv1{
        background: #fff1f0;
        .close{ display: none; }
        .notice-type{ color: #f00; }
        .ico-attention{ background-position: 0 0; }
        .content{
            >h4{
                color: #f5190a;
            }
        }

    }
    &.lv2{
        background: #fff9ed;
        .close{ display: none; }
        .notice-type{ color: #ff7d13; }
        .content{
            >h4{
                color: #ff7d13;
            }
        }
    }
    &.lv3{
        background: #fff9ed;
        .notice-type{ display: none; }
        .content{
            >h4{
                color: #666666;
            }
        }
    }
}
